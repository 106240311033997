<template>
  <form class="form" novalidate="novalidate">
    <div class="row align-items-center gx-4 mb-5">
      <label class="col-lg-3 mb-2 mb-lg-0 form-label fw-semi-bold required">Description</label>
      <div class="col-lg-9">
        <input
          v-model="form.description"
          :class="['form-control form-control-solid', validateModel($v.form.description.$error)]"
        />
      </div>
    </div>
    <div class="row align-items-center gx-4 mb-5">
      <label class="col-lg-3 mb-2 mb-lg-0 form-label fw-semi-bold required">Contract</label>
      <div class="col-lg-9">
        <div id="kt_new_adjustment_contract">
          <px-select
            v-if="contracts.length"
            v-model="form.proposal_scope_id"
            classes="form-select form-select-solid"
            :state="$v.form.proposal_scope_id.$error"
            placeholder="Select contract"
            :items="contracts"
            track-by="id"
            :text-by="['attributes.number', ' ', '|', ' ', 'v', 'attributes.version']"
            select2-id="select2_new_adjustment_contract"
          />
        </div>
      </div>
    </div>
    <div class="row align-items-center gx-4 mb-5">
      <label class="col-lg-3 mb-2 mb-lg-0 form-label fw-semi-bold">Date</label>
      <div class="col-lg-9">
        <flat-pickr
          v-model="form.date"
          :config="{
            altFormat: 'M j, Y',
            dateFormat: 'Y-m-d',
            static: true,
          }"
          class="form-control form-control-solid"
          placeholder="Select date"
        />
      </div>
    </div>
    <div class="row align-items-center">
      <label class="col-lg-3 mb-2 mb-lg-0 form-label fw-semi-bold required">Amount</label>
      <div class="col-lg-9">
        <input
          v-model="form.amount"
          :class="['form-control form-control-solid', validateModel($v.form.amount.$error)]"
        />
      </div>
    </div>

    <div class="d-flex flex-stack mt-8">
      <px-btn color="light" extended-classes="btn-active-light-primary me-2" @click.native="close">Cancel </px-btn>
      <px-btn v-if="isUpdate" :in-process="updateInProcess" :disabled="updateInProcess" @click.native="onUpdate">
        Update
      </px-btn>
      <px-btn v-else :in-process="createInProcess" :disabled="createInProcess" @click.native="onSubmit">Create </px-btn>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import AddAdjustmentMixin from '@/mixins/AddAdjustmentMixin';

export default {
  props: {
    isUpdate: {
      type: Boolean,
    },
    contracts: {
      type: Array,
      required: true,
    },
    adjustmentId: {
      type: String,
    },
    description: {
      type: String,
    },
    proposalScopeId: {
      type: String,
    },
    date: {
      type: String,
    },
    amount: {
      type: [String, Number],
    },
  },
  mixins: [AddAdjustmentMixin],
  computed: {
    ...mapGetters({
      message: 'adjustments/message',
    }),
  },
  data() {
    return {
      scopeTypes: [
        {
          id: '1',
          attributes: {
            title: 'Landscaping',
          },
        },
        {
          id: '2',
          attributes: {
            title: 'Swimming Pool',
          },
        },
        {
          id: '3',
          attributes: {
            title: 'Woodworking',
          },
        },
      ],

      updateInProcess: false,
      createInProcess: false,
    };
  },
  methods: {
    ...mapActions({
      actionAdjustmentCreate: 'adjustments/create',
      actionAdjustmentUpdate: 'adjustments/update',
    }),
    close() {
      this.$emit('onClose', null);
    },
    async initForm(data = null) {
      await this.v_toast(this.message?.status, 'Created successfully!', async () => {
        await this.$v.form.$reset();
        await this.defaultValues();
        this.$emit('onClose', data);
      });
    },
    async onSubmit() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return false;
      }

      this.createInProcess = true;

      let data = this.form;
      const newAdjustment = await this.actionAdjustmentCreate(data);

      await this.initForm(newAdjustment);

      this.createInProcess = false;
    },
    async onUpdate() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return false;
      }

      this.updateInProcess = true;

      let data = this.form;
      const newAdjustment = await this.actionAdjustmentUpdate({
        id: this.adjustmentId,
        attributes: { ...data },
      });

      await this.initForm(newAdjustment.data.data);

      this.updateInProcess = false;
    },
  },
  mounted() {
    if (this.description) {
      this.form.description = this.description;
    }

    if (this.proposalScopeId) {
      this.form.proposal_scope_id = this.proposalScopeId;
    }

    if (this.date) {
      this.form.date = this.date;
    }

    if (this.amount) {
      this.form.amount = this.amount;
    }
  },
};
</script>
