import { maxValue, minValue, required } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      form: {
        proposal_scope_id: '',
        payment_due_amount: '',
        payment_due_note: '',
      },
    };
  },
  validations() {
    return {
      form: {
        proposal_scope_id: { required },
        payment_due_amount: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(this.currentAmountOutstanding),
        },
        payment_due_note: { required },
      },
    };
  },
  methods: {
    defaultValues() {
      this.form = {
        proposal_scope_id: '',
        payment_due_amount: '',
        payment_due_note: '',
      };
    },
  },
};
