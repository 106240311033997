var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative min-h-200px"},[(_vm.isLoading)?_c('px-loader',{attrs:{"is-full-window":false,"bg":"blue"}}):_vm._e(),_c('div',[_c('p',{staticClass:"mb-4"},[_vm._v(" Your customer can view the proposal at this link: "),_c('br'),_c('router-link',{attrs:{"to":{ name: 'project-preview', params: { id: _vm.secret } },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.urlForCopy())+" ")])],1),_c('div',{staticClass:"d-flex mb-2"},[_c('div',{staticClass:"position-relative w-100"},[_c('input',{class:[
            'form-control form-control-solid me-3 flex-grow-1 pe-10',
            { 'bg-success text-inverse-success': _vm.isLinkCopied } ],attrs:{"type":"text","readonly":""},domProps:{"value":_vm.urlForCopy()}}),(!_vm.isLinkCopied)?_c('px-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: 'Copy Link',
            delay: { show: 400, hide: 0 },
          }),expression:"{\n            content: 'Copy Link',\n            delay: { show: 400, hide: 0 },\n          }"}],attrs:{"color":"transparent","extended-classes":"btn-icon btn-active-color-primary position-absolute top-0 end-0 h-100","title":"Copy Link"},nativeOn:{"click":function($event){return _vm.doCopy.apply(null, arguments)}}},[_c('span',{staticClass:"svg-icon svg-icon-muted svg-icon-2hx me-0"},[_c('inline-svg',{attrs:{"src":"/assets/media/icons/duotune/general/gen054.svg"}})],1)]):_c('span',{staticClass:"btn btn-icon position-absolute top-0 end-0 h-100"},[_c('i',{staticClass:"bi bi-check-lg text-white"})])],1)]),_c('p',{staticClass:"small text-muted mb-8"},[_vm._v("Copy the link and share it with your customer.")]),_c('div',{staticClass:"d-flex mb-2"},[_c('div',{staticClass:"position-relative w-100"},[_c('input',{class:[
            'form-control form-control-solid me-3 flex-grow-1 pe-10',
            { 'bg-success text-inverse-success': _vm.isCodeCopied } ],attrs:{"type":"text","readonly":""},domProps:{"value":_vm.code}}),(!_vm.isCodeCopied)?_c('px-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: 'Copy Code',
            delay: { show: 400, hide: 0 },
          }),expression:"{\n            content: 'Copy Code',\n            delay: { show: 400, hide: 0 },\n          }"}],attrs:{"color":"transparent","extended-classes":"btn-icon btn-active-color-primary position-absolute top-0 end-0 h-100","title":"Copy Code"},nativeOn:{"click":function($event){return _vm.doCodeCopy.apply(null, arguments)}}},[_c('span',{staticClass:"svg-icon svg-icon-muted svg-icon-2hx me-0"},[_c('inline-svg',{attrs:{"src":"/assets/media/icons/duotune/general/gen054.svg"}})],1)]):_c('span',{staticClass:"btn btn-icon position-absolute top-0 end-0 h-100"},[_c('i',{staticClass:"bi bi-check-lg text-white"})])],1)]),_c('p',{staticClass:"small text-muted mb-8"},[_vm._v("Copy the code and share it with your customer.")]),_c('div',{staticClass:"d-flex flex-stack pt-4"},[_c('px-btn',{attrs:{"color":"light","extended-classes":"btn-active-light-primary me-2"},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}},[_vm._v("Cancel")]),_c('div',[_c('px-btn',{attrs:{"color":"light-primary"},nativeOn:{"click":function($event){return _vm.doCopyExtended.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.isLinkAndCodeCopied ? 'Copied!' : 'Copy only')+" ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }