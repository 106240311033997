import {
  required,
  requiredIf,
  // minValue,
  // maxValue
} from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      form: {
        has_contracts: true,
        proposal_scope_id: '',
        date: '',
        amount: '',
        cash: false,
      },
    };
  },
  validations() {
    return {
      form: {
        proposal_scope_id: { required: requiredIf((prop) => prop.has_contracts) },
        amount: {
          required,
          // minValue: minValue(1),
          // maxValue: maxValue(this.currentAmountOutstanding),
        },
      },
    };
  },
  methods: {
    defaultValues() {
      this.form = {
        has_contracts: true,
        proposal_scope_id: '',
        date: '',
        amount: '',
        cash: false,
      };
    },
  },
};
