<template>
  <div class="position-relative min-h-200px">
    <px-loader v-if="isLoading" :is-full-window="false" bg="blue" />
    <div>
      <p class="mb-4">
        Your customer can view the proposal at this link:
        <br />
        <router-link :to="{ name: 'project-preview', params: { id: secret } }" target="_blank">
          {{ urlForCopy() }}
        </router-link>
      </p>

      <div class="d-flex mb-2">
        <div class="position-relative w-100">
          <input
            :class="[
              'form-control form-control-solid me-3 flex-grow-1 pe-10',
              { 'bg-success text-inverse-success': isLinkCopied },
            ]"
            type="text"
            :value="urlForCopy()"
            readonly
          />

          <px-btn
            v-if="!isLinkCopied"
            color="transparent"
            extended-classes="btn-icon btn-active-color-primary position-absolute top-0 end-0 h-100"
            v-tooltip="{
              content: 'Copy Link',
              delay: { show: 400, hide: 0 },
            }"
            title="Copy Link"
            @click.native="doCopy"
          >
            <span class="svg-icon svg-icon-muted svg-icon-2hx me-0">
              <inline-svg src="/assets/media/icons/duotune/general/gen054.svg" />
            </span>
          </px-btn>
          <span v-else class="btn btn-icon position-absolute top-0 end-0 h-100">
            <i class="bi bi-check-lg text-white" />
          </span>
        </div>
      </div>
      <p class="small text-muted mb-8">Copy the link and share it with your customer.</p>

      <div class="d-flex mb-2">
        <div class="position-relative w-100">
          <input
            :class="[
              'form-control form-control-solid me-3 flex-grow-1 pe-10',
              { 'bg-success text-inverse-success': isCodeCopied },
            ]"
            type="text"
            :value="code"
            readonly
          />

          <px-btn
            v-if="!isCodeCopied"
            color="transparent"
            extended-classes="btn-icon btn-active-color-primary position-absolute top-0 end-0 h-100"
            v-tooltip="{
              content: 'Copy Code',
              delay: { show: 400, hide: 0 },
            }"
            title="Copy Code"
            @click.native="doCodeCopy"
          >
            <span class="svg-icon svg-icon-muted svg-icon-2hx me-0">
              <inline-svg src="/assets/media/icons/duotune/general/gen054.svg" />
            </span>
          </px-btn>
          <span v-else class="btn btn-icon position-absolute top-0 end-0 h-100">
            <i class="bi bi-check-lg text-white" />
          </span>
        </div>
      </div>
      <p class="small text-muted mb-8">Copy the code and share it with your customer.</p>

      <!--begin::Actions-->
      <div class="d-flex flex-stack pt-4">
        <px-btn color="light" extended-classes="btn-active-light-primary me-2" @click.native="close">Cancel</px-btn>

        <div>
          <px-btn color="light-primary" @click.native="doCopyExtended">
            {{ isLinkAndCodeCopied ? 'Copied!' : 'Copy only' }}
          </px-btn>
        </div>
      </div>
      <!--end::Actions-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemId: {
      type: String,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
    secret: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLinkCopied: false,
      isCodeCopied: false,
      isLinkAndCodeCopied: false,
      isLoading: false,
    };
  },
  methods: {
    getCurrentBaseUrl() {
      return `${window.location.protocol}//${window.location.host}/`;
    },
    urlForCopy() {
      return `${this.getCurrentBaseUrl()}projects/preview/${this.secret}`;
    },
    urlAndCodeForCopy() {
      return `Proposal Link : ${this.getCurrentBaseUrl()}projects/preview/${this.secret}\nCode: ${this.code}`;
    },
    doCopy() {
      this.$copyText(this.urlForCopy()).then(() => {
        this.isLinkCopied = true;

        setTimeout(() => {
          this.isLinkCopied = false;
        }, 3000);
      });
    },
    doCodeCopy() {
      this.$copyText(this.code).then(() => {
        this.isCodeCopied = true;

        setTimeout(() => {
          this.isCodeCopied = false;
        }, 3000);
      });
    },
    doCopyExtended() {
      this.$copyText(this.urlAndCodeForCopy()).then(() => {
        this.isLinkAndCodeCopied = true;

        setTimeout(() => {
          this.isLinkAndCodeCopied = false;
        }, 3000);
      });
    },
    close() {
      this.$emit('onClose', true);
    },
  },
  mounted() {
    this.isLoading = true;

    this.isLoading = false;
  },
};
</script>
