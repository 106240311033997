<template>
  <form class="form" novalidate="novalidate">
    <div v-if="contracts && contracts.length" class="row align-items-center gx-4 mb-5">
      <label class="col-lg-3 mb-2 mb-lg-0 form-label fw-semi-bold required">Contract</label>
      <div class="col-lg-9">
        <div id="kt_new_adjustment_contract">
          <!--@input="onContractChange"-->
          <px-select
            v-model="form.proposal_scope_id"
            classes="form-select form-select-solid"
            :state="$v.form.proposal_scope_id.$error"
            placeholder="Select contract"
            :items="contracts"
            track-by="id"
            :text-by="['attributes.number', ' ', '|', ' ', 'v', 'attributes.version']"
            select2-id="select2_new_adjustment_contract"
          />
        </div>
      </div>
    </div>
    <div class="row align-items-center gx-4 mb-5">
      <label class="col-lg-3 mb-2 mb-lg-0 form-label fw-semi-bold">Date</label>
      <div class="col-lg-9">
        <flat-pickr
          v-model="form.date"
          :config="{
            altFormat: 'M j, Y',
            dateFormat: 'Y-m-d',
            static: true,
            maxDate: today,
          }"
          class="form-control form-control-solid"
          placeholder="Select date"
        />
      </div>
    </div>
    <div class="row align-items-center">
      <label class="col-lg-3 mb-2 mb-lg-0 form-label fw-semi-bold required">Amount</label>
      <div class="col-lg-9 d-flex align-items-center">
        <input
          v-model="form.amount"
          type="number"
          :class="['form-control form-control-solid', validateModel($v.form.amount.$error)]"
        />
        <div class="form-check form-check-sm form-check-custom form-check-solid ms-4">
          <input v-model="form.cash" class="form-check-input" type="checkbox" />
        </div>
      </div>
    </div>

    <div class="d-flex flex-stack mt-8">
      <px-btn color="light" extended-classes="btn-active-light-primary me-2" @click.native="close">Cancel</px-btn>
      <px-btn
        v-if="contracts && contracts.length"
        :in-process="createInProcess"
        :disabled="createInProcess"
        @click.native="onSubmit"
      >
        Create
      </px-btn>
      <px-btn v-else :in-process="updateInProcess" :disabled="updateInProcess" @click.native="onUpdate">Save </px-btn>
    </div>
  </form>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

import AddPaymentMixin from '@/mixins/AddPaymentMixin';

export default {
  props: {
    contracts: {
      type: Array,
    },
    paymentId: {
      type: String,
    },
    date: {
      type: String,
    },
    amount: {
      type: [Number, String],
    },
    cash: {
      type: Boolean,
    },
  },
  mixins: [AddPaymentMixin],
  computed: {
    ...mapGetters({
      message: 'payments/message',
      project: 'singleProject/project',
    }),
    today() {
      return moment(Date.now()).format('Y-M-D');
    },
  },
  data() {
    return {
      scopeTypes: [
        {
          id: '1',
          attributes: {
            title: 'Landscaping',
          },
        },
        {
          id: '2',
          attributes: {
            title: 'Swimming Pool',
          },
        },
        {
          id: '3',
          attributes: {
            title: 'Woodworking',
          },
        },
      ],

      createInProcess: false,
      updateInProcess: false,

      initialAmount: 0,

      currentAmountOutstanding: null,
    };
  },
  methods: {
    ...mapActions({
      actionPaymentCreate: 'payments/create',
      actionPaymentUpdate: 'payments/update',
      actionChangeBalanceOutstanding: 'singleProject/changeBalanceOutstanding',
    }),
    close() {
      this.$emit('onClose', null);
    },
    async initForm(data = null) {
      await this.v_toast(this.message?.status, 'Created successfully!', async () => {
        await this.$v.form.$reset();
        await this.defaultValues();
        this.$emit('onClose', data);
      });
    },
    async onSubmit() {
      this.$v.form.$touch();

      // if (this.$v.form.$anyError) {
      //   if (this.form.amount < 1) {
      //     this.v_toast(301, `Amount couldn't be less then at least $1`, () => {}, { timer: 5000 });
      //   }
      //
      //   if (this.form.amount > this.currentAmountOutstanding) {
      //     this.v_toast(301, `Amount couldn't be more then $${this.currentAmountOutstanding}`, () => {}, {
      //       timer: 5000,
      //     });
      //   }
      //
      //   return false;
      // }

      this.createInProcess = true;

      let data = this.form;

      const response = await this.actionPaymentCreate(data);

      this.createInProcess = false;

      if (response.status === 400) {
        this.v_toast(response.status, response.data[0], () => {}, {
          timer: 5000,
        });
      } else {
        await this.actionChangeBalanceOutstanding({
          amount: response.data.data.attributes.amount,
          operation: 'decrease',
        });

        await this.initForm(response);
      }
    },
    async onUpdate() {
      this.$v.form.$touch();

      // if (this.$v.form.$anyError) {
      //   if (this.form.amount < 1) {
      //     this.v_toast(301, `Amount couldn't be less then at least $1`, () => {}, { timer: 5000 });
      //   }
      //
      //   if (this.form.amount > this.currentAmountOutstanding) {
      //     this.v_toast(301, `Amount couldn't be more then $${this.currentAmountOutstanding}`, () => {}, {
      //       timer: 5000,
      //     });
      //   }
      //
      //   return false;
      // }

      this.updateInProcess = true;

      let data = this.form;
      delete data.has_contracts;
      delete data.proposal_scope_id;

      const response = await this.actionPaymentUpdate({
        id: this.paymentId,
        attributes: {
          amount: data.amount,
          cash: data.cash,
          date: data.date,
        },
      });

      this.updateInProcess = false;

      if (response.status === 422) {
        this.v_toast(response.status, `Amount ${response.data.errors.amount[0]}`, () => {}, {
          timer: 5000,
        });
      } else {
        let newAmount = 0;
        let operation = '';

        if (data.amount === this.initialAmount) {
          newAmount = data.amount;
          operation = 'do nothing';
        } else if (data.amount < this.initialAmount) {
          newAmount = data.amount - this.initialAmount;
          operation = 'decrease';
        } else {
          newAmount = this.initialAmount - data.amount;
          operation = 'increase';
        }

        await this.actionChangeBalanceOutstanding({
          amount: newAmount,
          operation: operation,
        });

        await this.initForm({ id: this.paymentId, attributes: { ...data } });
      }
    },

    // onContractChange(val) {
    //   const project = this.project?.attributes?.proposal_scopes?.data?.find((el) => el.id === val) || null;
    //
    //   this.currentAmountOutstanding = project?.attributes?.outstanding || null;
    // },
  },
  mounted() {
    if (!this.contracts) {
      this.form.has_contracts = false;
    }

    if (this.date) {
      this.form.date = this.date;
    }

    if (this.amount) {
      this.initialAmount = this.amount;
      this.form.amount = this.amount;
    }

    if (this.cash) {
      this.form.cash = this.cash;
    }
  },
};
</script>
