import { required } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      form: {
        description: '',
        proposal_scope_id: '',
        date: '',
        amount: '',
      },
    };
  },
  validations: {
    form: {
      description: { required },
      proposal_scope_id: { required },
      amount: { required },
    },
  },
  methods: {
    defaultValues() {
      this.form = {
        description: '',
        proposal_scope_id: '',
        date: '',
        amount: '',
      };
    },
  },
};
