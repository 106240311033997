<template>
  <div v-if="!isContractLoading && cContract && cContract.data">
    <h2 class="h3 fw-bold mb-4">{{ scopeType }}</h2>
    <div class="row">
      <div class="col-lg-8 mb-4 mb-lg-0">
        <div class="p-4 py-lg-8 px-lg-12 border-dashed-long rounded mb-11">
          <div class="row justify-content-between">
            <div class="col-lg-5 mb-4 mb-lg-0">
              <div class="mb-8">
                <div class="text-uppercase text-ls text-muted fs-9">Contract:</div>
                <strong>
                  {{ cContract.data.attributes.number }}
                  v{{ cContract.data.attributes.version }}
                </strong>
              </div>

              <div>
                <div class="text-uppercase text-ls text-muted fs-9">Last Payment:</div>
                <strong v-if="statements && statements.data.last_payment && statements.data.last_payment.date">
                  {{ statements.data.last_payment.date | moment('DD MMM, YYYY') }}
                </strong>
                <strong v-else>No payments yet :(</strong>
              </div>
            </div>

            <div v-if="!isStatementsLoading" class="col-lg-6 text-lg-end">
              <div class="d-flex justify-content-between mb-6 mb-lg-3">
                <div class="w-300px mb-1 mb-lg-0">Original contract subtotal:</div>
                <div class="w-175px">{{ $n(statements.data.sub_total, 'currency', 'en-CA') }}</div>
              </div>
              <div class="d-flex justify-content-between mb-6 mb-lg-3">
                <div class="w-300px mb-1 mb-lg-0">Total change order subtotal:</div>
                <div class="w-175px">
                  {{ $n(statements.data.change_orders_sum, 'currency', 'en-CA') }}
                </div>
              </div>
              <div class="d-flex justify-content-between fw-semi-bold mb-6 mb-lg-3">
                <div class="w-300px mb-1 mb-lg-0">Full Project:</div>
                <div class="w-175px">
                  {{ $n(statements.data.full_sum, 'currency', 'en-CA') }}
                </div>
              </div>
              <div class="d-flex justify-content-between mb-6 mb-lg-3">
                <div class="w-300px mb-1 mb-lg-0">HST:</div>
                <div class="w-175px">
                  {{ $n(statements.data.hst, 'currency', 'en-CA') }}
                </div>
              </div>
              <div class="d-flex justify-content-between fw-semi-bold">
                <div class="w-300px mb-1 mb-lg-0">Total:</div>
                <div class="w-175px">
                  {{ $n(statements.data.total, 'currency', 'en-CA') }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="['mb-4', { 'd-print-none': changeOrders && changeOrders.data && changeOrders.data.length }]">
          <h4 class="h5 fw-bold mb-4">Change Orders</h4>
          <div class="position-relative">
            <px-loader v-if="isChangeOrdersLoading" :is-full-window="false" bg="blue" />
            <div v-if="changeOrders && changeOrders.data && changeOrders.data.length" class="table-responsive mx-n3">
              <table class="table table-hover table-row-bordered table-row-dashed align-middle">
                <thead class="fs-10 fw-bold text-uppercase text-ls-sm align-middle">
                  <tr>
                    <th class="pb-2">Description</th>
                    <th class="pb-2">ID</th>
                    <th class="w-200px pb-2">Date</th>
                    <th class="w-130px text-end pb-2">Subtotal</th>
                    <th class="w-40px" />
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="changeOrdersItem in changeOrders.data" :key="changeOrdersItem.id">
                    <td>
                      {{ changeOrdersItem.attributes.construction_details || 'No description' }}
                    </td>
                    <td>
                      {{ changeOrdersItem.attributes.number }}
                      v{{ changeOrdersItem.attributes.version }}
                    </td>
                    <td>
                      {{ changeOrdersItem.attributes.updated_at | moment('YYYY.MM.DD') }}
                    </td>
                    <td class="text-end">
                      {{ $n(changeOrdersItem.attributes.sub_total, 'currency', 'en-CA') }}
                    </td>
                    <td class="w-40px">
                      <router-link
                        class="text-hover-primary"
                        :to="{ name: 'proposal-preview', params: { id: changeOrdersItem.id } }"
                        target="_blank"
                      >
                        <i class="bi bi-eye" />
                      </router-link>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="fw-semi-bold">
                    <td colspan="2" />
                    <td>Subtotal of change orders:</td>
                    <td class="text-end">
                      {{ $n(totalByItems(changeOrders.data, 'sub_total'), 'currency', 'en-CA') }}
                    </td>
                    <td />
                  </tr>
                </tfoot>
              </table>
            </div>
            <px-placeholder v-else class="d-print-none" />
          </div>
        </div>

        <div :class="['mb-4', { 'd-print-none': adjustments && adjustments.data && adjustments.data.length }]">
          <h4 class="h5 fw-bold mb-4">Adjustments</h4>
          <div class="position-relative">
            <px-loader v-if="isAdjustmentsLoading" :is-full-window="false" bg="blue" />
            <div v-if="adjustments && adjustments.data && adjustments.data.length" class="table-responsive mx-n3">
              <table class="table table-hover table-row-bordered table-row-dashed align-middle">
                <thead class="fs-10 fw-bold text-uppercase text-ls-sm align-middle">
                  <tr>
                    <th class="pb-2">Description</th>
                    <th class="w-200px pb-2">Date</th>
                    <th class="w-125px text-end pb-2">Amount</th>
                    <th class="w-40px" />
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="adjustmentsItem in adjustments.data" :key="adjustmentsItem.id">
                    <td>
                      {{ adjustmentsItem.attributes.description || 'No description' }}
                    </td>
                    <td>
                      {{ adjustmentsItem.attributes.date | moment('YYYY.MM.DD') }}
                    </td>
                    <td class="text-end">
                      {{ $n(adjustmentsItem.attributes.amount, 'currency', 'en-CA') }}
                    </td>
                    <td>
                      <px-dropdown
                        :items="[
                          { title: 'Edit', action: () => editAdjustmentItem(adjustmentsItem) },
                          {
                            title: 'Duplicate',
                            disabled: duplicateAdjustmentInProcess,
                            inProcess: duplicateAdjustmentInProcess,
                            action: () => duplicateAdjustmentItem(adjustmentsItem),
                          },
                          {
                            title: 'Delete',
                            disabled: deleteAdjustmentInProcess,
                            inProcess: deleteAdjustmentInProcess,
                            action: () => deleteAdjustmentItem(adjustmentsItem.id),
                          },
                        ]"
                      />
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="fw-semi-bold">
                    <td />
                    <td>Subtotal of adjustments:</td>
                    <td class="text-end">
                      {{ $n(totalByItems(adjustments.data, 'amount'), 'currency', 'en-CA') }}
                    </td>
                    <td />
                  </tr>
                </tfoot>
              </table>
            </div>
            <px-placeholder v-else />
          </div>
        </div>

        <div :class="['mb-6', { 'd-print-none': payments && payments.data && payments.data.length }]">
          <h4 class="h5 fw-bold mb-4">Payments</h4>
          <div class="position-relative">
            <px-loader v-if="isPaymentsLoading" :is-full-window="false" bg="blue" />
            <div v-if="payments && payments.data && payments.data.length" class="table-responsive mx-n3">
              <table class="table table-hover table-row-bordered table-row-dashed align-middle">
                <thead class="fs-10 fw-bold text-uppercase text-ls-sm align-middle">
                  <tr>
                    <th class="pb-2">Date</th>
                    <th class="w-125px text-end pb-2">Amount</th>
                    <th class="w-40px" />
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="paymentsItem in payments.data" :key="paymentsItem.id">
                    <td>
                      <div class="d-flex align-items-center">
                        <i
                          :class="[
                            'fa fa-genderless fs-2 me-2',
                            { 'text-danger': !paymentsItem.attributes.cash },
                            { 'text-success': paymentsItem.attributes.cash },
                          ]"
                        />
                        {{ paymentsItem.attributes.date | moment('YYYY.MM.DD') }}
                      </div>
                    </td>
                    <td class="text-end">
                      {{ $n(paymentsItem.attributes.amount, 'currency', 'en-CA') }}
                    </td>
                    <td class="text-end">
                      <px-dropdown
                        :items="[
                          { title: 'Edit', action: () => editPaymentItem(paymentsItem) },
                          {
                            title: 'Duplicate',
                            disabled: duplicateInProcess,
                            inProcess: duplicateInProcess,
                            action: () => duplicatePaymentItem(paymentsItem),
                          },
                          {
                            title: 'Delete',
                            disabled: deleteInProcess,
                            inProcess: deleteInProcess,
                            action: () =>
                              deletePaymentItem({ id: paymentsItem.id, amount: paymentsItem.attributes.amount }),
                          },
                        ]"
                      />
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="fw-semi-bold">
                    <td class="text-end">Subtotal of payments:</td>
                    <td class="text-end">
                      {{ $n(totalByItems(payments.data, 'amount'), 'currency', 'en-CA') }}
                    </td>
                    <td />
                  </tr>
                </tfoot>
              </table>
            </div>
            <px-placeholder v-else />
          </div>
        </div>

        <div class="px-4 px-lg-12">
          <div class="row justify-content-end">
            <div v-if="!isStatementsLoading" class="col-lg-6 text-lg-end">
              <div class="d-flex justify-content-between mb-6 mb-lg-3">
                <div class="w-300px mb-1 mb-lg-0">HST Received:</div>
                <div class="w-175px">
                  {{ $n(statements.data.hst_received, 'currency', 'en-CA') }}
                </div>
              </div>
              <div class="d-flex justify-content-between mb-6 mb-lg-3">
                <div class="w-300px mb-1 mb-lg-0">Amount Outstanding:</div>
                <div class="w-175px">
                  {{ $n(statements.data.outstanding, 'currency', 'en-CA') }}
                </div>
              </div>
              <div class="d-flex justify-content-between fw-semi-bold mb-6 mb-lg-3">
                <div class="w-300px mb-1 mb-lg-0">Outstanding with HST:</div>
                <div class="w-175px">
                  {{ $n(statements.data.outstanding_with_hst, 'currency', 'en-CA') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="bg-light rounded p-4 p-lg-8 mb-12">
          <h3 class="text-uppercase text-ls-sm fs-7 fw-bold text-gray-600 mb-4">Project Invoice</h3>
          <div v-if="!isStatementsLoading" class="mb-5">
            <div class="text-uppercase text-ls text-muted fs-9">Project Balance:</div>
            <strong class="fw-semi-bold">{{ $n(statements.data.full_sum, 'currency', 'en-CA') }} +HST</strong>
          </div>

          <div class="mb-5">
            <div class="text-uppercase text-ls text-danger fs-9">Due Immediately:</div>
            <strong class="fw-bold text-danger">
              <span v-if="cContract.data.attributes.payment_due_amount">
                {{ $n(cContract.data.attributes.payment_due_amount, 'currency', 'en-CA') }}
              </span>
              <span v-else>No yet...</span>
            </strong>
          </div>
          <div class="mb-5">
            <div class="text-uppercase text-ls text-muted fs-9">Notes:</div>
            <strong class="fw-semi-bold">
              <span v-if="cContract.data.attributes.payment_due_note">
                {{ cContract.data.attributes.payment_due_note }}
              </span>
              <span v-else>No yet...</span>
            </strong>
          </div>

          <br />

          <h3 class="text-uppercase text-ls-sm fs-7 fw-bold text-gray-600 mb-4">Payment Details</h3>
          <div class="mb-5">
            <div class="text-uppercase text-ls text-muted fs-9">Cheque:</div>
            <strong class="fw-semi-bold">Royal Stone Landscaping & Design</strong>
          </div>
          <div>
            <div class="text-uppercase text-ls text-muted fs-9">Online Banking:</div>
            <strong class="fw-semi-bold">Ask your project manager</strong>
          </div>
        </div>

        <div class="px-4 px-lg-8">
          <h3 class="text-uppercase text-ls-sm fs-7 fw-bold text-gray-600 mb-4">Payment Schedule</h3>
          <div
            v-for="(paymentScheduleItem, index) in cContract.data.attributes.payment_schedule_items.data"
            :key="paymentScheduleItem.id"
            :class="['d-flex', { 'mt-5': index !== 0 }]"
          >
            <div class="fw-semi-bold w-50px me-2">{{ paymentScheduleItem.attributes.percentage }}%</div>
            <div>{{ paymentScheduleItem.attributes.note }}</div>
          </div>
        </div>
      </div>
    </div>

    <px-new-modal :is-visible="isEditAdjustmentModalOpened" :close-modal="closeEditAdjustmentModal" modal-size="md">
      <template #title>
        <h3>Edit Adjustment</h3>
      </template>
      <template #content>
        <pxm-new-adjustment
          :is-update="true"
          :contracts="statements"
          :adjustment-id="adjustmentForUpdate.adjustmentId"
          :description="adjustmentForUpdate.description"
          :proposalScopeId="adjustmentForUpdate.proposalScopeId"
          :date="adjustmentForUpdate.date"
          :amount="adjustmentForUpdate.amount"
          @onClose="closeEditAdjustmentModal"
        />
      </template>
    </px-new-modal>

    <px-new-modal :is-visible="isEditPaymentModalOpened" :close-modal="closeEditPaymentModal" modal-size="md">
      <template #title>
        <h3>Edit Payment</h3>
      </template>
      <template #content>
        <pxm-payment
          :payment-id="paymentForUpdate.paymentId"
          :date="paymentForUpdate.date"
          :amount="paymentForUpdate.amount"
          :cash="paymentForUpdate.cash"
          @onClose="closeEditPaymentModal"
        />
      </template>
    </px-new-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import PxmNewAdjustment from '@/modals/PxmNewAdjustment';
import PxmPayment from '@/modals/PxmPayment';

export default {
  props: {
    subject: {
      type: Object,
      required: true,
    },
    scopeType: {
      type: String,
      required: true,
    },
  },
  components: { PxmNewAdjustment, PxmPayment },
  computed: {
    ...mapGetters({
      statements: 'projectsSingle/statements',
      contract: 'projectsSingle/contract',
      paymentsMessage: 'payments/message',
      adjustmentsMessage: 'adjustments/message',
      statementsAll: 'singleProject/statements',
      statementsChangeOrders: 'singleProject/statementsChangeOrders',
      statementsAdjustments: 'singleProject/statementsAdjustments',
      statementsPayments: 'singleProject/statementsPayments',
    }),
    cContract() {
      return this.contract?.find((el) => el.type === this.scopeType) || null;
    },
    statements() {
      return this.statementsAll?.find((el) => el.type === this.scopeType) || null;
    },
    changeOrders() {
      return this.statementsChangeOrders?.find((el) => el.type === this.scopeType) || null;
    },
    adjustments() {
      return this.statementsAdjustments?.find((el) => el.type === this.scopeType) || null;
    },
    payments() {
      return this.statementsPayments?.find((el) => el.type === this.scopeType) || null;
    },
  },
  data() {
    return {
      isEditPaymentModalOpened: false,
      isEditAdjustmentModalOpened: false,
      isContractLoading: true,
      isStatementsLoading: true,
      isChangeOrdersLoading: true,
      isPaymentsLoading: true,
      isAdjustmentsLoading: true,
      paymentForUpdate: {
        paymentId: null,
        date: null,
        amount: null,
        cash: null,
      },
      adjustmentForUpdate: {
        adjustmentId: null,
        description: null,
        proposalScopeId: null,
        date: null,
        amount: null,
      },

      duplicateAdjustmentInProcess: false,
      deleteAdjustmentInProcess: false,
      duplicateInProcess: false,
      deleteInProcess: false,
    };
  },
  async mounted() {
    const fieldsArr = [
      'current',
      'number',
      'version',
      'payment_due_amount',
      'payment_due_note',
      'payment_schedule_items',
      'scope_type',
    ];

    await this.actionContract({
      id: this.subject.id,
      scope_type: this.scopeType,
      fields: this.arrayToString(fieldsArr),
    });
    this.isContractLoading = false;

    if (this.cContract?.data?.id) {
      await this.actionInitStatementChangeOrders({
        subject_id: this.subject.id,
        scope_type: this.scopeType,
      });
      this.isChangeOrdersLoading = false;

      await this.actionInitStatements({ id: this.cContract.data.id, scope_type: this.scopeType });
      this.isStatementsLoading = false;

      await this.actionInitStatementAdjustments({
        proposal_scope_id: this.cContract.data.id,
        scope_type: this.scopeType,
      });
      this.isAdjustmentsLoading = false;

      await this.actionInitStatementPayments({ proposal_scope_id: this.cContract.data.id, scope_type: this.scopeType });
      this.isPaymentsLoading = false;
    }

    this.$emit('onContractLoaded', this.cContract?.data || null);
  },
  methods: {
    ...mapActions({
      actionContract: 'projectsSingle/contract',

      actionCreateAdjustment: 'adjustments/create',
      actionDeleteAdjustment: 'adjustments/delete',

      actionCreatePayment: 'payments/create',
      actionUpdatePayment: 'payments/update',
      actionDeletePayment: 'payments/delete',

      actionInitStatements: 'singleProject/initStatements',
      actionUpdateStatements: 'singleProject/updateStatements',

      actionInitStatementChangeOrders: 'singleProject/initStatementChangeOrders',

      actionInitStatementAdjustments: 'singleProject/initStatementAdjustments',
      actionDuplicateStatementAdjustment: 'singleProject/duplicateStatementAdjustment',
      actionDeleteStatementAdjustment: 'singleProject/deleteStatementAdjustment',
      actionUpdateStatementAdjustment: 'singleProject/updateStatementAdjustment',

      actionInitStatementPayments: 'singleProject/initStatementPayments',
      actionDuplicateStatementPayment: 'singleProject/duplicateStatementPayment',
      actionDeleteStatementPayment: 'singleProject/deleteStatementPayment',
      actionUpdateStatementPayment: 'singleProject/updateStatementPayment',
      actionChangeBalanceOutstanding: 'singleProject/changeBalanceOutstanding',
    }),
    totalByItems(items, fieldName) {
      let totalPrice = 0;

      if (items.length) {
        items.map((el) => {
          totalPrice += +el.attributes[fieldName];
        });
      }

      return totalPrice;
    },

    async duplicateAdjustmentItem(item) {
      try {
        this.duplicateAdjustmentInProcess = true;

        const preparedAdjustmentItem = item.attributes;
        preparedAdjustmentItem.proposal_scope_id = preparedAdjustmentItem.proposal_scope.data.id;
        delete preparedAdjustmentItem.created_at;
        delete preparedAdjustmentItem.updated_at;
        delete preparedAdjustmentItem.proposal_scope;
        const newAdjustmentItem = await this.actionCreateAdjustment(preparedAdjustmentItem);

        this.duplicateAdjustmentInProcess = false;

        await this.v_toast(this.adjustmentsMessage?.status, 'Duplicated successfully!', () => {
          this.actionDuplicateStatementAdjustment({ item: newAdjustmentItem.data.data, scopeType: this.scopeType });
          this.actionUpdateStatements({ id: this.cContract.data.id, scopeType: this.scopeType });
        });
      } catch (e) {
        console.log(e);
      }
    },
    editAdjustmentItem(data) {
      this.adjustmentForUpdate = {
        adjustmentId: data.id,
        description: data.attributes.description,
        proposalScopeId: data.attributes.proposal_scope.data.id,
        date: data.attributes.date,
        amount: data.attributes.amount,
      };
      this.isEditAdjustmentModalOpened = true;
    },
    async deleteAdjustmentItem(id) {
      try {
        this.deleteAdjustmentInProcess = true;

        await this.actionDeleteAdjustment(id);
        this.actionDeleteStatementAdjustment({ id, scopeType: this.scopeType });
        this.actionUpdateStatements({ id: this.cContract.data.id, scopeType: this.scopeType });

        this.deleteAdjustmentInProcess = false;
      } catch (e) {
        console.log(e);
      }
    },

    async duplicatePaymentItem(item) {
      try {
        this.duplicateInProcess = true;

        const preparedPaymentItem = item.attributes;
        preparedPaymentItem.proposal_scope_id = preparedPaymentItem.proposal_scope.data.id;
        delete preparedPaymentItem.created_at;
        delete preparedPaymentItem.updated_at;
        delete preparedPaymentItem.proposal_scope;
        const newPaymentItem = await this.actionCreatePayment(preparedPaymentItem);

        this.duplicateInProcess = false;

        await this.v_toast(this.paymentsMessage?.status, 'Duplicated successfully!', () => {
          this.actionDuplicateStatementPayment({ item: newPaymentItem.data.data, scopeType: this.scopeType });
          this.actionUpdateStatements({ id: this.cContract.data.id, scopeType: this.scopeType });

          this.actionChangeBalanceOutstanding({
            amount: newPaymentItem.data.data.attributes.amount,
            operation: 'decrease',
          });
        });
      } catch (e) {
        console.log(e);
      }
    },
    editPaymentItem(data) {
      this.paymentForUpdate = {
        paymentId: data.id,
        date: data.attributes.date,
        amount: data.attributes.amount,
        cash: data.attributes.cash,
      };
      this.isEditPaymentModalOpened = true;
    },
    async deletePaymentItem(params) {
      try {
        this.deleteInProcess = true;

        await this.actionDeletePayment(params.id);
        await this.actionDeleteStatementPayment({ id: params.id, scopeType: this.scopeType });
        await this.actionUpdateStatements({ id: this.cContract.data.id, scopeType: this.scopeType });

        await this.actionChangeBalanceOutstanding({
          amount: params.amount,
          operation: 'increase',
        });

        this.deleteInProcess = false;
      } catch (e) {
        console.log(e);
      }
    },

    openEditAdjustmentModal() {
      this.isEditAdjustmentModalOpened = true;
    },
    async closeEditAdjustmentModal(data) {
      if (data) {
        this.actionUpdateStatementAdjustment({
          id: data.id,
          scopeType: this.scopeType,
          attributes: { ...data.attributes },
        });
        this.actionUpdateStatements({ id: this.cContract.data.id, scopeType: this.scopeType });
        this.adjustmentForUpdate = {
          adjustmentId: null,
          description: null,
          proposalScopeId: null,
          date: null,
          amount: null,
        };
      }

      this.isEditAdjustmentModalOpened = false;
    },

    openEditPaymentModal() {
      this.isAddNewPaymentModalOpened = true;
    },
    async closeEditPaymentModal(data) {
      if (data) {
        this.actionUpdateStatementPayment({
          id: data.id,
          scopeType: this.scopeType,
          attributes: { ...data.attributes },
        });
        this.actionUpdateStatements({ id: this.cContract.data.id, scopeType: this.scopeType });
        this.paymentForUpdate = {
          paymentId: null,
          date: null,
          amount: null,
          cash: null,
        };
      }

      this.isEditPaymentModalOpened = false;
    },
  },
};
</script>
