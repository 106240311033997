<template>
  <form class="form" novalidate="novalidate">
    <div v-if="contracts && contracts.length" class="row align-items-center gx-4 mb-5">
      <label class="col-lg-3 mb-2 mb-lg-0 form-label fw-semi-bold required">Contract</label>
      <div class="col-lg-9">
        <div id="kt_new_adjustment_contract">
          <px-select
            v-model="form.proposal_scope_id"
            classes="form-select form-select-solid"
            :state="$v.form.proposal_scope_id.$error"
            placeholder="Select contract"
            :items="contracts"
            track-by="id"
            :text-by="['attributes.number', ' ', '|', ' ', 'v', 'attributes.version']"
            select2-id="select2_payment_due_immediately_contract"
            @input="onContractChange"
          />
        </div>
      </div>
    </div>
    <div class="row align-items-center gx-4 mb-5">
      <label class="col-lg-3 mb-2 mb-lg-0 form-label fw-semi-bold required">Amount</label>
      <div class="col-lg-9">
        <input
          v-model="form.payment_due_amount"
          :class="['form-control form-control-solid', validateModel($v.form.payment_due_amount.$error)]"
        />
      </div>
    </div>
    <div class="row align-items-center gx-4">
      <label class="col-lg-3 mb-2 mb-lg-0 form-label fw-semi-bold required">Description</label>
      <div class="col-lg-9">
        <input
          v-model="form.payment_due_note"
          :class="['form-control form-control-solid', validateModel($v.form.payment_due_note.$error)]"
        />
      </div>
    </div>

    <div class="d-flex flex-stack mt-8">
      <px-btn color="light" extended-classes="btn-active-light-primary me-2" @click.native="close">Cancel</px-btn>
      <px-btn :in-process="saveInProcess" :disabled="saveInProcess" @click.native="onSave">Save </px-btn>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import PaymentDueImmediatelyMixin from '@/mixins/PaymentDueImmediatelyMixin';

export default {
  props: {
    contracts: {
      type: Array,
      required: true,
    },
  },
  mixins: [PaymentDueImmediatelyMixin],
  computed: {
    ...mapGetters({
      message: 'proposals/message',
      project: 'singleProject/project',
    }),
  },
  data() {
    return {
      saveInProcess: false,

      currentAmountOutstanding: null,
    };
  },
  methods: {
    ...mapActions({
      actionProposalsUpdate: 'proposals/update',
    }),
    close() {
      this.$emit('onClose', null);
    },
    async initForm(data = null) {
      await this.v_toast(this.message?.status, 'Created successfully!', async () => {
        await this.$v.form.$reset();
        await this.defaultValues();
        this.$emit('onClose', data);
      });
    },
    async onSave() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        if (this.form.payment_due_amount < 1) {
          this.v_toast(301, `Amount couldn't be less then at least $1`, () => {}, { timer: 5000 });
        }

        if (this.form.payment_due_amount > this.currentAmountOutstanding) {
          this.v_toast(301, `Amount couldn't be more then $${this.currentAmountOutstanding}`, () => {}, {
            timer: 5000,
          });
        }

        return false;
      }

      this.saveInProcess = true;

      let data = this.form;
      const proposalScopeId = data.proposal_scope_id;
      delete data.proposal_scope_id;
      const selectedProposal = this.contracts.find((el) => el.id === proposalScopeId);

      await this.actionProposalsUpdate({
        id: proposalScopeId,
        ...data,
      });

      this.saveInProcess = false;

      await this.initForm({
        scopeType: selectedProposal.attributes.scope_type,
        attributes: { ...data },
      });
    },
    onContractChange(val) {
      const project = this.project?.attributes?.proposal_scopes?.data?.find((el) => el.id === val) || null;

      this.currentAmountOutstanding = project?.attributes?.outstanding || null;
    },
  },
};
</script>
